import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from 'app/App';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'app/styles/app.scss';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
