import React, {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Loader from 'shared/ui/Loader';
import PhotoPage from 'pages/PhotoPage';
import VideoPage from 'pages/VideoPage';
import ReactGA from 'react-ga';
import Home from 'pages/Home';

ReactGA.initialize('GTM-WP63JJ8R');

function logPageView() {
	ReactGA.set({page: window.location.pathname + window.location.search});
	ReactGA.pageview(window.location.pathname + window.location.search);
}

const AppRouter = () => (
	<Suspense fallback={<Loader />}>
		<Routes history={createBrowserHistory()} onUpdate={logPageView}>
			<Route index element={<Home />} />
			<Route path="/photo-gallery" element={<PhotoPage />} />
			<Route path="/video-gallery" element={<VideoPage />} />
			<Route path="*" element={<Navigate to="/" />} />
		</Routes>
	</Suspense>
);

export default AppRouter;
